/** @format */
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
/* eslint-disable-next-line */

interface CallToActionProps {
  partner?: string;
}

export function CallToAction(props: CallToActionProps) {
  const router = useRouter();
  const { partner } = props;
  const goToSignupPage = (partnerName) => {
    if (partnerName) {
      router.push({
        pathname: "/signup",
        query: { partner: partnerName },
      });
    } else {
      router.push({
        pathname: "/signup",
      });
    }
  };
  const goToContactUsPage = (partnerName) => {
    if (partnerName) {
      router.push({
        pathname: "/contact-us",
        query: { partner: partnerName },
      });
    } else {
      router.push({
        pathname: "/contact-us",
      });
    }
  };

  let footerLine1 = "";
  let footerLine2 = "";
  let footerLine3 = "";

  if (
    router.pathname === "/" ||
    router.pathname.includes("/customer-stories")
  ) {
    footerLine1 = "Ready To Learn More?";
    footerLine2 = "";
    footerLine3 =
      "Schedule a free demo with our knowledgeable Fleet Consultants to explore the RoadFlex Platform and benefits.";
  } else if (router.pathname === "/prefunded") {
    footerLine1 = "Empower your fleet";
    footerLine2 = "with RoadFlex";
    footerLine3 = "Get up and running in a few simple steps";
  } else if (router.pathname === "/fleetcard") {
    footerLine1 = "Make your fleet stronger";
    footerLine2 = "with RoadFlex today";
    footerLine3 = "Become a member today in a few easy steps";
  } else if (
    router.pathname === "/competition" ||
    router.pathname === "/savings"
  ) {
    footerLine1 = "Experience the difference.";
    footerLine2 = "Join RoadFlex today!";
    footerLine3 =
      "Contact us with any questions or to learn more about how RoadFlex can make your fleet stronger.";
  } else if (router.pathname === "/career") {
    footerLine1 = "Strengthen your fleet with";
    footerLine2 = "the power of RoadFlex";
    footerLine3 = "No personal or business credit checks.";
  } else if (router.pathname === "/partners/fleetup") {
    footerLine1 = "3 min to apply!";
    footerLine2 = "";
    footerLine3 =
      "Get approved within 1 day. No credit score check. No contracts - cancel anytime.";
  } else {
    footerLine1 = "Manage your fleet";
    footerLine2 = "expenses with RoadFlex";
    footerLine3 = "Become a member today in a few easy steps";
  }

  return (
    <>
      <div className="relative w-full text-white bg-brown-750">
        <div className="relative">
          <div className="container relative flex flex-row items-center justify-center w-full py-6 my-6 md:py-20">
            <div className="flex flex-col justify-center w-full ">
              <div className="text-xl font-semibold sm:text-3xl md:text-5xl">
                {footerLine1}
              </div>
              <div className="my-2 text-xl font-semibold sm:text-3xl md:text-5xl">
                {footerLine2}
              </div>
              <div className="sm:mt-8 max-w-[450px]">{footerLine3}</div>
              {!partner && (
                <div className="flex flex-row mt-12">
                  <span>
                    <Button
                      variant={ButtonVariant.White}
                      onClick={() => goToContactUsPage(partner)}
                      className="!px-4 sm:!px-8 font-medium"
                      size={ButtonSize.Large}
                    >
                      Book a Demo
                    </Button>
                  </span>
                  <span className="ml-4">
                    <Button
                      variant={ButtonVariant.GrayOutlineWhiteText}
                      onClick={() => goToSignupPage(null)}
                      className="!px-4 sm:!px-8 font-medium"
                      size={ButtonSize.Large}
                    >
                      Apply Now
                    </Button>
                  </span>
                </div>
              )}
              {partner && (
                <div className="flex flex-row mt-12">
                  <span className="">
                    <Button
                      variant={ButtonVariant.White}
                      onClick={() => goToContactUsPage(partner)}
                      className="!px-4 sm:!px-8 font-medium"
                      size={ButtonSize.Large}
                    >
                      Schedule A Demo
                    </Button>
                  </span>
                  <span className="ml-4">
                    <Button
                      variant={ButtonVariant.GrayOutlineWhiteText}
                      onClick={() => goToSignupPage(partner)}
                      className="!px-4 sm:!px-8 font-medium"
                      size={ButtonSize.Large}
                    >
                      Apply Now
                    </Button>
                  </span>
                </div>
              )}
            </div>
            <div className="relative hidden w-full md:block max-w-[450px]">
              <div className="bg-[#ffa500]  h-[150px] w-[300px] -rotate-12  top-[30%] blur-3xl absolute"></div>
              <Image
                className="h-12 p-0 m-0"
                src={`${NEXT_PUBLIC_CDN_URL}/common/footer/images/footer-card.png`}
                alt="RoadFlex Fuel and Fleet Card"
                width={611}
                height={368}
                layout="responsive"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CallToAction;
